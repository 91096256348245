/* Copyright 2013 - 2024 Waiterio LLC */
import { useQueryClient } from '@tanstack/react-query'
import addRole from '@multilocale/client/addRole.js'

export default function useAddRoleMutation() {
  const queryClient = useQueryClient()

  return {
    mutationFn: role => addRole(role),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['roles'] })
    },
  }
}
