/* Copyright 2013 - 2024 Waiterio LLC */
import localStorage from 'localStorage'
import {
  setAccessTokenForMultilocaleClient,
  setAccessTokenCallbackForMultilocaleClient,
} from '@multilocale/client/accessToken.js'
import { setRefreshTokenForMultilocaleClient } from '@multilocale/client/refreshToken.js'
import IS_BROWSER from '@monorepo/env/IS_BROWSER.js'
import clearCookies from '@monorepo/shared/clearCookies.js'

export default function clearSession() {
  if (IS_BROWSER) {
    localStorage.clear()

    setAccessTokenCallbackForMultilocaleClient(null)

    setAccessTokenForMultilocaleClient(null)
    setRefreshTokenForMultilocaleClient(null)

    clearCookies()
  }
}
