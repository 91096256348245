/* Copyright 2013 - 2024 Waiterio LLC */
import setCookie from '@monorepo/shared/setCookie.js'
import {
  setAccessTokenForMultilocaleClient,
  setAccessTokenCallbackForMultilocaleClient,
} from '@multilocale/client/accessToken.js'
import { setRefreshTokenForMultilocaleClient } from '@multilocale/client/refreshToken.js'
import getAccessTokenPayload from './getAccessTokenPayload.js'
import setAccessToken from './setAccessToken.js'
import setRefreshToken from './setRefreshToken.js'
import identify from '../identify.js'

export default async function storeNewSession({ accessToken, refreshToken }) {
  try {
    setAccessToken(accessToken)
    setRefreshToken(refreshToken)

    setAccessTokenForMultilocaleClient(accessToken, setAccessToken)
    setRefreshTokenForMultilocaleClient(refreshToken, setRefreshToken)
    setAccessTokenCallbackForMultilocaleClient(setAccessToken)

    let accessTokenPayload = getAccessTokenPayload()

    setCookie('globoUserId', accessTokenPayload?.metadata?.userId)
    setCookie('globoSignature', accessTokenPayload?.metadata?.globoSignature)
    identify()

    return true
  } catch (error) {
    console.error('error', error)
    throw error
  }
}
