/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useTranslation } from '@multilocale/react/index.js'
import { jsx } from '@emotion/react'
import Modal from '@stiloso/components/Modal.js'
import Button from '@stiloso/components/Button.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'

const ChangePhraseValueDialog = ({ close, defaultValue, language, submit }) => {
  const { t } = useTranslation()

  const onSubmit = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const value = formData.get('value')
    submit(value, language)
  }

  return (
    <Modal close={close}>
      <div css={{ margin: 16 }}>
        <div css={sectionTitle}>{t('Value')}</div>
        <form onSubmit={onSubmit} css={{ marginTop: 8 }}>
          <textarea
            placeholder={t('value')}
            name="value"
            defaultValue={defaultValue}
            data-testid="value-input"
            css={{
              width: '100%',
              fontSize: 16,
              padding: 16,
              resize: 'vertical',
              minHeight: 160,
              border: '1px solid #e4e4e4',
            }}
            required
            autoFocus
          />
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Save')}
          </Button>
        </form>
      </div>
    </Modal>
  )
}

export default ChangePhraseValueDialog
