/* Copyright 2013 - 2024 Waiterio LLC */
import checkStringNotEmpty from '@monorepo/validation/checkStringNotEmpty.js'
import delete_ from './delete_.js'

export default function deletePhrases({ key, projectName }) {
  checkStringNotEmpty(key)
  checkStringNotEmpty(projectName)

  let url = `phrases?key=${key}&project=${projectName}`

  return delete_({ url }).catch(error => {
    throw new Error(`Couldn't delete phrases\n${error}`)
  })
}
