/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useParams } from 'react-router-dom'
import { useTranslation } from '@multilocale/react/index.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import addPhrases from '@multilocale/client/addPhrases.js'
import useDeletePhrasesMutation from '@multilocale/react/useDeletePhrasesMutation.js'
import getPhrases from '@multilocale/client/getPhrases.js'
import updatePhrase from '@multilocale/client/updatePhrase.js'
import translateToAllLanguages from '@multilocale/client/translateToAllLanguages.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import clickable from '@stiloso/styles/clickable.js'
import singleLine from '@stiloso/styles/singleLine.js'
import IconAdd from '@stiloso/icons/IconAdd.js'
import IconDelete from '@stiloso/icons/IconDelete.js'
import useProject from '@multilocale/react/useProject.js'
import ErrorDialog from '@stiloso/components/ErrorDialog.js'
import LoadingDialog from '@stiloso/components/LoadingDialog.js'
import DeleteDialog from '@stiloso/components/DeleteDialog.js'
import Layout from '../components/Layout.js'
import GoogleTranslatePhraseDialog from '../dialogs/GoogleTranslatePhraseDialog.js'
import ChangePhraseKeyDialog from '../dialogs/ChangePhraseKeyDialog.js'
import ChangePhraseValueDialog from '../dialogs/ChangePhraseValueDialog.js'
import ChangeProjectsDialog from '../dialogs/ChangeProjectsDialog.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

const card = css({
  label: 'card',
  backgroundColor: 'white',
  display: 'inherit',
  borderRadius: '4px',
})

const field = css`
  label: field;
  display: flex;
  height: 48px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
`

const label = css`
  label: label;
  display: flex;
  ${singleLine};
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 8px;
  line-height: 48px;
  cursor: inherit;
`

const section = css`
  border: 0;
  margin: 0 auto;
  max-width: 480px;
  margin-bottom: 16px;
`

const sectionTitle = css`
  label: sectiont-title;
  display: flex;
  line-height: 48px;
  font-weight: bold;
  border: 0px;
  border-bottom: 1px;
  border-color: #e4e4e4;
  border-style: solid;
  padding-left: 16px;
  padding-right: 16px;
`

const tableHeader = css`
  font-weight: bold;
  margin: 16px;
`

const tableRow = css`
  display: flex;
  flex: 1;
`

const tableRowDivider = css`
  border-bottom: 1px solid #e4e4e4;
`

const tableColumn = css`
  margin: 16px;
  font-size: 16px;
`

export const paths = ['/projects/:projectName/phrases/:key_']

const PhrasePage = () => {
  useLoggedInSession()
  const { t } = useTranslation()
  const { key_: key, projectName } = useParams()
  const { data: project } = useProject(projectName)
  const { defaultLocale } = project
  const deleteMutation = useDeletePhrasesMutation()
  let [loading, setLoading] = useState()
  let [error, setError] = useState()
  let [progress, setProgress] = useState()
  let [phrases, setPhrases] = useState()
  let [loadingDialog, setLoadingDialog] = useState()
  let [errorDialog, setErrorDialog] = useState()
  let [deleteConfirmationDialog, setDeleteConfirmationDialog] = useState()
  let [changePhraseKeyDialog, setChangePhraseKeyDialog] = useState()
  let [changePhraseValueDialog, setChangePhraseValueDialog] = useState()
  let [changeProjectsDialog, setChangeProjectsDialog] = useState()
  let [googleTranslatePhraseDialog, setGoogleTranslatePhraseDialog] = useState()

  let defaultPhrase = {}
  let translatedPhrases = {}

  if (phrases) {
    defaultPhrase = phrases.find(phrase => phrase.language === defaultLocale)
    translatedPhrases = phrases
      .sort((a, b) => (a.language > b.language ? 1 : -1))
      .filter(phrase => phrase.language !== defaultLocale)
  }

  const { projects } = defaultPhrase || {}

  const fetchPhrases = async key => {
    try {
      setLoading(true)

      let phrases = await getPhrases({ key, project: projectName })

      setPhrases(phrases)
      setLoading(false)
    } catch (error) {
      setError(error?.message)
    }
  }

  useEffect(() => {
    fetchPhrases(key)
  }, [key])

  const showErrorDialog = error => {
    setErrorDialog(error ? error.toString() : null)
  }

  const showDeleteConfirmationDialog = () => setDeleteConfirmationDialog(true)
  const hideDeleteConfirmationDialog = () => setDeleteConfirmationDialog(false)

  const deletePhrases = () => {
    deleteMutation.mutate(
      { key, projectName },
      {
        onSuccess: () => {
          hideDeleteConfirmationDialog()
          window.location.href = `/projects/${projectName}/phrases`
        },
        onError: error => {
          hideDeleteConfirmationDialog()
          showErrorDialog(error)
        },
      },
    )
  }

  const showChangePhraseKeyDialog = key => {
    setChangePhraseKeyDialog({ key })
  }

  const hideChangePhraseKeyDialog = () => {
    setChangePhraseKeyDialog(false)
  }

  const showChangePhraseValueDialog = ({ value, language, submit }) => {
    setChangePhraseValueDialog({ defaultValue: value, language, submit })
  }

  const hideChangePhraseValueDialog = () => {
    setChangePhraseValueDialog(null)
  }

  const updatePhrases = async newPhrases => {
    setProgress(t('Saving changes') + '...')
    await addPhrases(newPhrases)
    setProgress(false)
    setLoadingDialog(false)
    fetchPhrases(key)
  }

  const updatePhrasesKey = async key => {
    try {
      hideChangePhraseKeyDialog()
      setLoadingDialog(true)
      setProgress('Changing phrases key...')
      const lastEditTime = new Date().getTime()

      const newPhrases = phrases.map(phrase => ({
        ...phrase,
        lastEditTime,
        key,
      }))

      await updatePhrases(newPhrases)
      window.location.replace(`/projects/${projectName}/phrases/${key}`)
    } catch (error) {
      setLoadingDialog(false)
      showErrorDialog(error)
    }
  }

  const updatePhrasesProject = async projects => {
    try {
      setLoadingDialog(true)
      setProgress('Changing phrases projects...')
      const lastEditTime = new Date().getTime()

      const newPhrases = phrases.map(phrase => ({
        ...phrase,
        lastEditTime,
        projects,
      }))

      await updatePhrases(newPhrases)
    } catch (error) {
      setLoadingDialog(false)
      showErrorDialog(error)
    }
  }

  const googleTranslatePhrase = async humanPhrase => {
    try {
      setGoogleTranslatePhraseDialog(false)
      setLoadingDialog(true)

      const lastEditTime = new Date().getTime()

      let newPhraseValue = defaultPhrase.value

      setProgress('Changing phrases value...')

      const firstLetterUppercase =
        defaultPhrase.key === defaultPhrase.key.toUpperCase()

      if (firstLetterUppercase) {
        newPhraseValue =
          newPhraseValue.charAt(0).toUpperCase() + newPhraseValue.slice(1)
      }

      let language2values = await translateToAllLanguages(newPhraseValue)

      let googleTranslatedPhrase = {
        ...humanPhrase,
        lastEditTime,
        value: language2values[humanPhrase.language],
        googleTranslate: true,
      }

      let newPhrases = [...phrases, googleTranslatedPhrase]

      setProgress(t('Saving changes') + '...')

      await addPhrases(newPhrases)

      setProgress(false)

      setLoadingDialog(false)

      fetchPhrases(defaultPhrase.key)
    } catch (error) {
      setLoadingDialog(false)
      showErrorDialog(error)
    }
  }

  const updateDefaultPhraseValue = async value => {
    try {
      hideChangePhraseValueDialog()
      setLoadingDialog(true)
      setProgress('Changing default phrase value...')

      const lastEditTime = new Date().getTime()
      let newPhrases = phrases

      const firstLetterUppercase =
        newPhrases[0].key === newPhrases[0].key.toUpperCase()

      if (firstLetterUppercase) {
        value = value.charAt(0).toUpperCase() + value.slice(1)
      }

      let language2values = await translateToAllLanguages(value)

      newPhrases = phrases.map(phrase => ({
        ...phrase,
        lastEditTime,
        value:
          phrase.language === defaultLocale
            ? value
            : language2values[phrase.language],
      }))

      await updatePhrases(newPhrases)
    } catch (error) {
      setLoadingDialog(false)
      showErrorDialog(error)
    }
  }

  const updatePhraseValue = async (value, language) => {
    try {
      hideChangePhraseValueDialog()
      setLoadingDialog(true)
      setProgress(t('Changing phrase value'))

      const lastEditTime = new Date().getTime()

      let phrase = phrases.find(phrase => phrase.language === language)

      let humanTranslatedPhrase = {
        ...phrase,
        lastEditTime,
        value,
        googleTranslate: false,
      }

      setProgress(t('Saving changes'))
      await updatePhrase(humanTranslatedPhrase)
      setProgress(false)
      setLoadingDialog(false)
      fetchPhrases(key)
    } catch (error) {
      setProgress(false)
      setLoadingDialog(false)
      setError(error && error.message)
    }
  }

  return (
    <Layout>
      <div css={{ margin: '32px auto', maxWidth: 1024, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{`${t('Phrase')} ${key}`}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            appearance="secondary"
            label={t('Delete')}
            onClick={showDeleteConfirmationDialog}
            Icon={IconDelete}
          />
        </AppBar>
        <div css={{ padding: 16 }}>
          {loading && <div className="loader" />}
          {!phrases && error && <div>{error}</div>}

          {phrases && (
            <>
              <div
                css={[card, clickable, section]}
                onClick={() => showChangePhraseKeyDialog(key)}
              >
                <div
                  css={[
                    sectionTitle,
                    { display: 'flex', alignItems: 'center', border: 0 },
                  ]}
                >
                  <span css={{ flexGrow: 1 }}>{t('Key')}</span>
                  <div css={{ fontWeight: 'normal' }}>{key}</div>
                </div>
              </div>
              <div css={[card, section]}>
                <div
                  css={[
                    clickable,
                    sectionTitle,
                    { display: 'flex', paddingRight: 0 },
                  ]}
                  onClick={() => setChangeProjectsDialog(true)}
                >
                  <span css={{ flexGrow: 1 }}>{t('Projects')}</span>
                  <IconAdd width={48} height={48} css={{ padding: 12 }} />
                </div>
                {projects?.map(project => (
                  <div key={project} css={field}>
                    <div css={label}>{project}</div>
                  </div>
                ))}
              </div>
              <div
                css={[
                  {
                    display: 'flex',
                    flexDirection: 'column',
                  },
                  card,
                ]}
              >
                <div css={[tableRow, tableRowDivider]}>
                  <div css={[tableHeader, { flex: 0.2 }]}>{t('Language')}</div>
                  <div css={[tableHeader, { flex: 0.8 }]}>
                    {t('Translation')}
                  </div>
                  <div css={[tableHeader, { flex: 0.2, textAlign: 'right' }]}>
                    {t('Translated with')}
                  </div>
                </div>
                {defaultPhrase && (
                  <div
                    onClick={() =>
                      showChangePhraseValueDialog({
                        value: defaultPhrase.value,
                        submit: updateDefaultPhraseValue,
                      })
                    }
                    css={[tableRow, clickable]}
                  >
                    <div css={[tableColumn, { flex: 0.2 }]}>
                      {t(getLanguageNameByCode(defaultPhrase.language))} (
                      {defaultPhrase.language})
                    </div>
                    <div css={[tableColumn, { flex: 0.8, lineHeight: '24px' }]}>
                      {defaultPhrase.value}
                    </div>
                    <div css={[tableColumn, { flex: 0.2, textAlign: 'right' }]}>
                      {t('Default')}
                    </div>
                  </div>
                )}
                {translatedPhrases.map(phrase => (
                  <div
                    key={phrase._id}
                    onClick={() =>
                      showChangePhraseValueDialog({
                        value: phrase.value,
                        language: phrase.language,
                        submit: updatePhraseValue,
                      })
                    }
                    css={[tableRow, clickable]}
                  >
                    <div css={[tableColumn, { flex: 0.2 }]}>
                      {t(getLanguageNameByCode(phrase.language))} (
                      {phrase.language})
                    </div>
                    <div css={[tableColumn, { flex: 0.8, lineHeight: '24px' }]}>
                      {phrase.value}
                    </div>
                    <div css={[tableColumn, { flex: 0.2, textAlign: 'right' }]}>
                      {phrase.googleTranslate === true
                        ? 'Google'
                        : 'Translator'}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      {loadingDialog && (
        <LoadingDialog title={t('Updating phrases')} message={progress} />
      )}
      {errorDialog && (
        <ErrorDialog
          message={errorDialog}
          close={() => setErrorDialog(false)}
        />
      )}

      {changePhraseKeyDialog && (
        <ChangePhraseKeyDialog
          defaultKey={changePhraseKeyDialog.key}
          close={hideChangePhraseKeyDialog}
          onSave={updatePhrasesKey}
        />
      )}

      {changeProjectsDialog && (
        <ChangeProjectsDialog
          projects={projects}
          onSave={updatePhrasesProject}
          close={() => setChangeProjectsDialog(false)}
        />
      )}

      {changePhraseValueDialog && (
        <ChangePhraseValueDialog
          close={hideChangePhraseValueDialog}
          {...changePhraseValueDialog}
        />
      )}

      {deleteConfirmationDialog && (
        <DeleteDialog
          title={t('Delete phrase')}
          message="This will permanently delete all the phrases with this key. Are you sure you want delete it?"
          onDelete={deletePhrases}
          close={hideDeleteConfirmationDialog}
          loading={deleteMutation.isLoading}
        />
      )}

      {googleTranslatePhraseDialog && (
        <GoogleTranslatePhraseDialog
          message="Phrase will be retranslated using google translate. Are you sure you want to remove the human translation?"
          phrase={googleTranslatePhraseDialog.phrase}
          delete={googleTranslatePhrase}
          close={() => setGoogleTranslatePhraseDialog(false)}
        />
      )}
    </Layout>
  )
}

export default PhrasePage
