/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, Suspense } from 'react'
import { jsx, css } from '@emotion/react'
import { Link, useParams } from 'react-router-dom'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import list from '@stiloso/styles/list.js'
import listItem from '@stiloso/styles/listItem.js'
import usePhrases from '@multilocale/react/usePhrases.js'
import useProject from '@multilocale/react/useProject.js'
import { useTranslation } from '@multilocale/react/index.js'
import fuzzySearch from '@monorepo/shared/fuzzySearch.js'
import SearchBar from '@stiloso/components/SearchBar.js'
import Spinner from '@stiloso/components/Spinner.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import ErrorBoundary from '../components/ErrorBoundary.js'

export const paths = ['/projects/:projectName/phrases']

export const searchBarStyles = css`
  box-shadow: none;
  border: 1px solid #dcdfe4;
  border-radius: 4px;
  transition: background-color 0.125s;
  background-color: #f7f8f9;

  :active {
    background-color: white;
  }
`

const PhrasesList = () => {
  let { projectName } = useParams()
  const { data: project } = useProject(projectName)
  const { t } = useTranslation()

  const { data: phrases } = usePhrases({
    language: project.defaultLocale || 'en',
    project: projectName,
  })
  const [filteredPhrases, setFilteredPhrases] = useState([])

  const onSearch = searchQuery => {
    if (searchQuery.length) {
      const filtered = fuzzySearch({
        query: searchQuery,
        list: phrases,
        properties: ['key'],
      })
      setFilteredPhrases(filtered)
    } else {
      setFilteredPhrases([])
    }
  }

  const onCancelSearch = () => {
    setFilteredPhrases([])
  }

  return (
    <div
      css={{ margin: '32px auto', maxWidth: 640, width: '100%', padding: 16 }}
    >
      {phrases.length > 0 && (
        <div
          css={{
            margin: '16px 0px',
            display: 'flex',
            flex: 1,
          }}
        >
          <div
            css={{
              width: '100%',
              maxWidth: 320,
              marginRight: 16,
            }}
          >
            <SearchBar
              placeholder={t('Search phrases')}
              onSearch={onSearch}
              onCancel={onCancelSearch}
              styles={searchBarStyles}
            />
          </div>
        </div>
      )}
      {phrases?.length > 0 && filteredPhrases.length < 1 && (
        <div css={list}>
          {phrases.map(({ _id, key }) => (
            <Link
              key={_id}
              to={`/projects/${projectName}/phrases/${encodeURIComponent(key)}`}
              target="_blank"
              rel="noopener noreferrer"
              css={listItem}
            >
              {key}
            </Link>
          ))}
        </div>
      )}
      {filteredPhrases.length > 0 && (
        <div css={list}>
          {filteredPhrases.map(({ _id, key }) => (
            <Link
              key={_id}
              to={`/projects/${projectName}/phrases/${encodeURIComponent(key)}`}
              css={listItem}
            >
              {key}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

const PhrasesPage = () => {
  useLoggedInSession()
  let { projectName } = useParams()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={{ margin: '32px auto', maxWidth: 640, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{`${t('Phrases')} ${projectName}`}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton
            label={t('Add phrase')}
            to={`/projects/${projectName}/phrases/new`}
          />
        </AppBar>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <PhrasesList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default PhrasesPage
