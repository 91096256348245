/* Copyright 2013 - 2024 Waiterio LLC */
import { useQuery } from '@tanstack/react-query'
import getPhrases from '@multilocale/client/getPhrases.js'

export default function usePhrases(parameters, options) {
  return useQuery({
    queryKey: ['phrases', { ...parameters }],
    queryFn: () => getPhrases(parameters),
    ...options,
  })
}
