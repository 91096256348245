/* Copyright 2013 - 2024 Waiterio LLC */

function getRootDomain() {
  let rootDomain

  if (typeof window !== 'undefined') {
    rootDomain = window.location.hostname
    let splits = rootDomain.split('.')

    if (splits.length > 2) {
      rootDomain = splits.slice(-2).join('.')
    }
  }

  return rootDomain
}

export default function setCookie(key, value) {
  if (typeof document !== 'undefined') {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    let expires = 'expires=' + date.toUTCString()
    document.cookie =
      `${key}=${encodeURIComponent(value)};` +
      `${expires};` +
      'path=/;' +
      `domain=${getRootDomain()};` +
      'SameSite=None; Secure'
  }
}
