/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState, Suspense } from 'react'
import { css, jsx } from '@emotion/react'
import { useParams } from 'react-router-dom'
import uuid from '@monorepo/shared/uuid.js'
import useProject from '@multilocale/react/useProject.js'
import useUpdateProjectMutation from '@multilocale/react/useUpdateProjectMutation.js'
import getPhrases from '@multilocale/client/getPhrases.js'
import addPhrases from '@multilocale/client/addPhrases.js'
import getLanguageNameByCode from '@monorepo/shared/getLanguageNameByCode.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import Spinner from '@stiloso/components/Spinner.js'
import list from '@stiloso/styles/list.js'
import listItem from '@stiloso/styles/listItem.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import Button from '@stiloso/components/Button.js'
import { useTranslation } from '@multilocale/react/index.js'
import slugify from '@monorepo/shared/slugify.js'
// import spinner from '@stiloso/styles/spinner.js'
import AddLanguageDialog from '@stiloso/components/AddLanguageDialog.js'
import translateToAllLanguages from '@multilocale/client/translateToAllLanguages.js'
import LoadingDialog from '@stiloso/components/LoadingDialog.js'
import Layout from '../components/Layout.js'
import getRoles from '../session/getRoles.js'
import useLoggedInSession from '../session/useLoggedInSession.js'
import ErrorBoundary from '../components/ErrorBoundary.js'

export const paths = ['/projects/:projectName']

const ProjectDetails = () => {
  const { projectName } = useParams()
  const { t } = useTranslation()
  const { data: project } = useProject(projectName)

  const [loadingProgress, setLoadingProgress] = useState('')
  const [error, setError] = useState(null)
  const [isProjectEdited, setIsProjectEdited] = useState(false)
  const [addLanguageDialog, setAddLanguageDialog] = useState(false)
  let [name, setName] = useState('')

  const canEditProject = !!getRoles().filter(
    ({ type }) => type === 'admin' || type === 'editor' || type === 'developer',
  ).length

  const changeName = event => {
    let name = event.target.value
    name = slugify(name)

    setName(name)
    setIsProjectEdited(true)
  }

  const saveProject = event => {
    event.preventDefault()

    if (isProjectEdited) {
      // mutation.mutate({
      //   ...project,
      //   name,
      //   customDomain,
      //   googleAnalyticsMeasurementId,
      //   lastEditTime: new Date().getTime(),
      // })

      setIsProjectEdited(false)
    }
  }

  const showAddLanguageDialog = () => {
    setAddLanguageDialog(true)
  }

  const hideAddLanguageDialog = () => {
    setAddLanguageDialog(false)
  }

  const addPhrasesForNewLanguage = async newLanguage => {
    try {
      hideAddLanguageDialog()

      setLoadingProgress(t('Getting all phrases of the project'))

      const phrases = await getPhrases({
        project: projectName,
        language: project.defaultLocale,
      })

      setLoadingProgress(
        t(
          `Translating existing phrases to ${getLanguageNameByCode(
            newLanguage,
          )} language`,
        ),
      )

      const languages = [newLanguage]

      const translations = await Promise.all(
        phrases.map(async phrase => {
          const language2value = await translateToAllLanguages(
            phrase.value,
            languages,
          )
          return {
            ...language2value,
            key: phrase.key,
          }
        }),
      )

      setLoadingProgress(t('Adding phrases to database'))

      const creationTime = new Date().getTime()
      const lastEditTime = creationTime

      const newPhrases = translations.map(translation => ({
        _id: uuid(),
        creationTime,
        lastEditTime,
        key: translation.key,
        value: translation[newLanguage],
        language: newLanguage,
        googleTranslate: true,
        projects: [project.name],
      }))

      await addPhrases(newPhrases)
      setLoadingProgress(null)
    } catch (error) {
      setLoadingProgress(null)
      setError(error && error.message)
    }
  }

  useEffect(() => {
    if (project) {
      setName(project.name)
    }
  }, [project])

  return (
    <>
      {project && (
        <>
          <div css={card}>
            <form onSubmit={saveProject} disabled>
              <div>
                <label htmlFor="name" css={label}>
                  {t('name')}
                </label>
                <input
                  id="name"
                  css={input}
                  value={name}
                  onChange={changeName}
                  required
                  minLength="1"
                  autoFocus
                  autoComplete="off"
                  data-lpignore="true"
                  data-form-type="other"
                />
              </div>

              <div>
                <div css={label}>{t('Id')}</div>
                <input css={input} value={project._id} readOnly />
              </div>

              {canEditProject && (
                <Button type="submit" css={{ marginTop: 16 }} fullWidth>
                  {t('Save project')}
                </Button>
              )}
            </form>
            {/* {mutation.isError && (
              <div css={{ color: 'red', marginTop: 16 }}>
                {mutation.error.message}
              </div>
            )} */}
          </div>
          <div
            css={{
              display: 'flex',
              flex: 1,
              justifyContent: 'space-between',
              marginTop: 32,
            }}
          >
            <div css={[sectionTitle]}>{t('Languages')}</div>
            <div
              css={{
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
            >
              <Button appearance="secondary" onClick={showAddLanguageDialog}>
                {t('Add language')}
              </Button>
            </div>
          </div>
          <div css={[list, { marginTop: 0 }]}>
            {project.locales.length > 0 &&
              project.locales.map(locale => (
                <div key={locale} css={listItem}>
                  {t(getLanguageNameByCode(locale))}
                </div>
              ))}
          </div>
        </>
      )}
      {addLanguageDialog && (
        <AddLanguageDialog
          item={project}
          useUpdateMutation={useUpdateProjectMutation}
          close={addPhrasesForNewLanguage}
        />
      )}
      {loadingProgress && (
        <LoadingDialog title={t('Please wait')} message={loadingProgress} />
      )}
      {error && (
        <div css={section}>
          <p css={{ color: 'red' }}>{error}</p>
        </div>
      )}
    </>
  )
}

const ProjectPage = () => {
  useLoggedInSession()
  const { t } = useTranslation()

  return (
    <Layout>
      <div css={section}>
        <div css={sectionTitle}>{t('Project')}</div>
        <ErrorBoundary>
          <Suspense fallback={<Spinner />}>
            <ProjectDetails />
          </Suspense>
        </ErrorBoundary>
      </div>
    </Layout>
  )
}

export default ProjectPage
