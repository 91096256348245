/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { useTranslation } from '@multilocale/react/index.js'
import { css, jsx } from '@emotion/react'
import { modalOuter, backdrop } from '@stiloso/components/Modal.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'

const ChangePhraseKeyDialog = ({ onSave, close, defaultKey }) => {
  const { t } = useTranslation()

  const onSubmit = event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const key = formData.get('key')
    onSave(key)
  }

  const modal = css`
    box-sizing: border-box;
    width: 480px;
    height: auto;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    z-index: 9999;
    background: #ffffff;
    position: relative;
    padding: 32px;
    padding-top: 16px;
  `

  return (
    <div css={modalOuter}>
      <div css={modal}>
        <div css={sectionTitle}>{t('Key')}</div>
        <form onSubmit={onSubmit}>
          <input
            name="key"
            placeholder={t('Key')}
            css={[input, { paddingLeft: 16, textAlign: 'left' }]}
            defaultValue={defaultKey}
            required
            autoFocus
            data-testid="key-input"
          />
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Save')}
          </Button>
        </form>
      </div>
      <div css={backdrop} onClick={close} data-testid="close-modal" />
    </div>
  )
}

export default ChangePhraseKeyDialog
