/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { setDefaultLanguage } from '@multilocale/react/index.js'
import useUser from '@multilocale/react/useUser.js'
import getUserId from './getUserId.js'

const LanguageSetter = () => {
  const userId = getUserId()
  const { data: user } = useUser(userId, { enabled: !!userId })
  setDefaultLanguage(user?.language)

  return null
}

export default LanguageSetter
