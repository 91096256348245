/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import getProjects from '@multilocale/client/getProjects.js'
import SignupPage from '@stiloso/pages/SignupPage.js'
import { useNavigate } from 'react-router-dom'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import getNameFromEmail from '@monorepo/shared/getNameFromEmail.js'
import postSignup from '../postSignup.js'
import storeNewSession from '../session/storeNewSession.js'

export const paths = ['/signup']

const SignupPageWrapper = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const signup = async ({ email, password }) => {
    let { firstName, lastName } = getNameFromEmail(email)
    let language = getBrowserLanguage()
    let signupResponse = await postSignup({
      email,
      password,
      firstName,
      lastName,
      language,
    })
    await storeNewSession(signupResponse)

    if (signupResponse.newOrganization) {
      navigate('/setup-project')
    } else {
      const projects = await getProjects()

      if (projects.length === 1) {
        navigate(`/projects/${projects[0].name}/phrases`)
      } else {
        navigate('/projects')
      }
    }
  }

  let brand = (
    <div css={{ color: 'var(--color-primary)', fontSize: 36 }}>
      {t('__brand__')}
    </div>
  )

  return <SignupPage brand={brand} signup={signup} />
}

export default SignupPageWrapper
