/* Copyright 2013 - 2024 Waiterio LLC */
import fetch from '@monorepo/fetch/index.js'
import getMultilocaleApiUrl from '@monorepo/env/getMultilocaleApiUrl.js'

export default ({ email, password, firstName, lastName, language }) =>
  fetch({
    url: getMultilocaleApiUrl() + '/api/signup',
    method: 'POST',
    body: { firstName, lastName, language },
    headers: {
      Authorization: `Basic ${btoa(email + ':' + password)}`,
    },
  })
