/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { css, jsx } from '@emotion/react'
import addProject from '@multilocale/client/addProject.js'
import getBrowserLanguage from '@monorepo/shared/getBrowserLanguage.js'
import languages from '@stiloso/shared/languages.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import Button from '@stiloso/components/Button.js'
import { useTranslation } from '@multilocale/react/index.js'
import LanguageAutocomplete from '@stiloso/components/LanguageAutocomplete.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/new']

const NewProjectPage = () => {
  useLoggedInSession()

  const { t } = useTranslation()
  const navigate = useNavigate()
  let [name, setName] = useState('')
  const language = getBrowserLanguage()
  const [selectedLanguage, setSelectedLanguage] = useState(language)

  const languages_ = Object.keys(languages).map(languageCode => ({
    code: languageCode,
    name: languages[languageCode],
  }))

  const changeName = event => {
    let name = event.target.value

    name = name.toLowerCase()
    name = name.replaceAll(' ', '-')

    setName(name)
  }

  const createProject = async event => {
    event.preventDefault()

    try {
      const project = {
        name: name.replace(/^-+|-+$/g, ''),
        defaultLocale: selectedLanguage,
        locales: [selectedLanguage],
      }

      await addProject(project)

      navigate('/projects')
    } catch (error) {
      // TO DO
    }
  }

  return (
    <Layout title={t('New project')}>
      <div css={section}>
        <div css={sectionTitle}>{t('New project')}</div>
        <form onSubmit={createProject} css={card}>
          <label htmlFor="nameInput" css={label}>
            {t('name')}
          </label>
          <input
            id="nameInput"
            css={input}
            value={name}
            onChange={changeName}
            required
            minLength="1"
            autoFocus
            autoComplete="off"
            data-lpignore="true"
            data-form-type="other"
          />
          <LanguageAutocomplete
            inputLabel="Default language"
            languages={languages_}
            selectedLanguage={selectedLanguage}
            onSelectLanguage={setSelectedLanguage}
          />
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Add project')}
          </Button>
        </form>
      </div>
    </Layout>
  )
}

export default NewProjectPage
