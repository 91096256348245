/* Copyright 2013 - 2024 Waiterio LLC */
import post from './post.js'

export default function translateToAllLanguages(value, languages) {
  return post({
    url: 'translate-to-all-languages',
    body: { value, languages },
  }).catch(error => {
    throw new Error(`Couldn't translate phrases\n${error}`)
  })
}
