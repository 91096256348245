/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

const link = css`
  color: var(--color-primary);
`

export const paths = ['/landing-pages']

const LandingsPage = () => {
  useLoggedInSession()

  return (
    <Layout title="Landing Pages">
      <div css={{ width: '100%', height: '100%', fontSize: 24, padding: 8 }}>
        <div css={{ padding: 8 }}>
          <h1>www</h1>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/"
              target="_blank"
              rel="noreferrer noopener"
            >
              home
            </a>
          </div>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/restaurant-pos-system"
              target="_blank"
              rel="noreferrer noopener"
            >
              pos system
            </a>
          </div>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/restaurant-website-builder/"
              target="_blank"
              rel="noreferrer noopener"
            >
              website builder
            </a>
          </div>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/food-truck-pos-system/"
              target="_blank"
              rel="noreferrer noopener"
            >
              food truck
            </a>
          </div>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/bar-pos-system/"
              target="_blank"
              rel="noreferrer noopener"
            >
              bars and clubs
            </a>
          </div>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.waiterio.com/en/referral/"
              target="_blank"
              rel="noreferrer noopener"
            >
              referral program
            </a>
          </div>
        </div>
        <div css={{ padding: 8 }}>
          <h2>Multilocale</h2>
          <div>
            <a
              css={link}
              href="/landing-page?url=https://www.multilocale.com/en/"
              target="_blank"
              rel="noreferrer noopener"
            >
              www
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LandingsPage
