/* Copyright 2013 - 2024 Waiterio LLC */
import { useMutation, useQueryClient } from '@tanstack/react-query'
import updateUser from '@multilocale/client/updateUser.js'

export default function useUserMutation() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: user => updateUser(user),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users'] })
    },
    onError: error => error,
  })
}
