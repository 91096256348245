/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useEffect, useState } from 'react'
import { jsx } from '@emotion/react'
import { useTranslation } from '@multilocale/react/index.js'
import Modal from '@stiloso/components/Modal.js'
import ModalHeader from '@stiloso/components/ModalHeader.js'
import IconDone from '@stiloso/icons/IconDone.js'
import Button from '@stiloso/components/Button.js'
import getProjects from '@multilocale/client/getProjects.js'

const ChangeProjectsDialog = props => {
  const { t } = useTranslation()
  let [projects, setProjects] = useState(props.projects)
  let [selectedProjects, setSelectedProjects] = useState(props.projects)

  useEffect(() => {
    const fetchProjects = async () => {
      let projects = await getProjects()
      projects = projects.map(({ name }) => name).sort()

      setProjects(projects)
    }

    fetchProjects()
  }, [])

  const onChangeProject = project => {
    const isProjectAlreadyAdded = selectedProjects.some(
      project_ => project === project_,
    )

    if (isProjectAlreadyAdded) {
      setSelectedProjects(prevState =>
        prevState.filter(_project => _project !== project),
      )
    } else {
      setSelectedProjects(prevState => [...prevState, project])
    }
  }

  const onSubmit = event => {
    event.preventDefault()
    props.onSave(selectedProjects)
    props.close()
  }

  return (
    <Modal close={props.close}>
      <form onSubmit={onSubmit}>
        <ModalHeader>{t('Projects')}</ModalHeader>
        {projects?.map(project => (
          <div
            key={project}
            onClick={() => onChangeProject(project)}
            css={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
              flex: 1,
              lineHeight: '48px',
              ':hover': { background: 'rgba(51, 181, 229, 0.3)' },
            }}
          >
            <div
              css={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flex: 0.25,
              }}
            >
              <IconDone
                css={{
                  width: selectedProjects.includes(project) ? 20 : 0,
                  fill: 'var(--color-base)',
                }}
              />
            </div>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flex: 0.75,
              }}
            >
              {project}
            </div>
            <div css={{ flex: 0.25 }} />
          </div>
        ))}

        <Button type="submit" css={{ marginTop: 16 }} fullWidth>
          {t('Save')}
        </Button>
      </form>
    </Modal>
  )
}

export default ChangeProjectsDialog
