/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { Link } from 'react-router-dom'
import useProjects from '@multilocale/react/useProjects.js'
import card from '@stiloso/styles/card.js'
import clickable from '@stiloso/styles/clickable.js'
import AppBar from '@stiloso/components/AppBar.js'
import AppBarButton from '@stiloso/components/AppBarButton.js'
import AppBarTitle from '@stiloso/components/AppBarTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects']

let projectCard = css([
  clickable,
  card,
  {
    lineHeight: '48px',
    width: '100%',
    marginTop: 16,
    padding: '0 16',
  },
])

const ProjectsPage = () => {
  const { t } = useTranslation()
  useLoggedInSession()
  let { data: projects } = useProjects()
  projects = projects?.sort((a, b) => (a.name > b.name ? 1 : -1))

  return (
    <Layout>
      <div css={{ margin: '32px auto', maxWidth: 640, width: '100%' }}>
        <AppBar>
          <AppBarTitle>{t('Projects')}</AppBarTitle>
          <div css={{ flexGrow: 1 }} />
          <AppBarButton label={t('New project')} to="/projects/new" />
        </AppBar>
        <div
          css={{
            padding: 16,
            display: 'flex',
            direction: 'column',
            flexWrap: 'wrap',
          }}
        >
          {projects?.map(({ name }) => (
            <Link
              key={name}
              css={projectCard}
              to={`/projects/${name}/phrases/`}
            >
              {name}
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default ProjectsPage
