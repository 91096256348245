/* Copyright 2013 - 2024 Waiterio LLC */
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client' // eslint-disable-line import/extensions
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { initMultilocale } from '@multilocale/react/index.js'
import MultilocaleProvider from '@multilocale/react/MultilocaleProvider.js'
import StageBadge from '@stiloso/components/StageBadge.js'
import ErrorBoundary from '@stiloso/components/ErrorBoundary.js'
import ScrollToTop from '@stiloso/components/ScrollToTop.js'
import allowSubdomainsCrossOrigin from '@monorepo/shared/allowSubdomainsCrossOrigin.js'
import getSentryBrowser from '@monorepo/telemetry/getSentryBrowser.js'
import Spinner from '@stiloso/components/Spinner.js'
import GlobalStyles from '@multilocale/shared/GlobalStyles.js'
import Routes from './Routes.js'
import LanguageSetter from './session/LanguageSetter.js'
import SessionRehydrator from './session/SessionRehydrator.js'

allowSubdomainsCrossOrigin()
initMultilocale({
  organizationId: 'e395dba6a14d8af4c2d35890',
  project: 'multilocale-app',
})

const Root = () => (
  <ErrorBoundary>
    <MultilocaleProvider>
      <SessionRehydrator>
        <Suspense fallback={<Spinner />}>
          <LanguageSetter />
          <ScrollToTop />
          <GlobalStyles />
          <Routes />
          <StageBadge />
        </Suspense>
      </SessionRehydrator>
    </MultilocaleProvider>
  </ErrorBoundary>
)

const router = createBrowserRouter([{ path: '*', Component: Root }])

createRoot(document.getElementById('root')).render(
  <RouterProvider router={router} />,
)

getSentryBrowser()
