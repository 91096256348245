/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import { useParams } from 'react-router-dom'
import useProjects from '@multilocale/react/useProjects.js'
import useUser from '@multilocale/react/useUser.js'
import Drawer from '@stiloso/components/Drawer.js'
import DrawerButton from '@stiloso/components/DrawerButton.js'
import DrawerTitle from '@stiloso/components/DrawerTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import IconFolders from '@stiloso/icons/IconFolders.js'
import IconSettings from '@stiloso/icons/IconSettings.js'
// import IconLayers from '@stiloso/icons/IconLayers.js'
import IconEarth from '@stiloso/icons/IconEarth.js'
import IconPowerOff from '@stiloso/icons/IconPowerOff.js'
import IconUsers from '@stiloso/icons/IconUsers.js'
import IconUser from '@stiloso/icons/IconUser.js'
import getUserId from '../session/getUserId.js'

const Layout = ({ children }) => {
  const { t } = useTranslation()
  let { data: projects } = useProjects()
  const { data: user } = useUser(getUserId())
  let { projectName } = useParams()

  projects = projects?.sort((a, b) =>
    a.creationTime > b.creationTime ? 1 : -1,
  )

  let pathname = ''

  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  projectName = projectName || projects?.[0]?.name

  let fullName = ''

  if (user.firstName && user.lastName) {
    fullName = `${user.firstName} ${user.lastName}`
  }

  const drawerButtons = [
    projects.length > 1 && {
      label: 'Projects',
      to: '/projects',
      Icon: IconFolders,
    },
    {
      label: 'Phrases',
      to: '/projects/{projectName}/phrases',
      Icon: IconEarth,
    },
    {
      label: 'Team',
      to: '/team',
      Icon: IconUsers,
    },
    {
      label: 'Settings',
      to: '/projects/{projectName}',
      Icon: IconSettings,
    },
    // {
    //   label: 'Landing Pages',
    //   to: '/projects/{projectName}/landing-pages',
    //   Icon: IconLayers,
    // },
  ].filter(_ => _)

  return (
    <div
      css={{
        display: 'grid',
        height: '100%',
        gridTemplateColumns: '200px auto',
        gridTemplateAreas: `
          'drawer children'
        `,
      }}
    >
      <Drawer>
        <DrawerTitle>{t('__brand__')}</DrawerTitle>
        {drawerButtons.map(({ label, to, Icon }) => (
          <DrawerButton
            key={to}
            label={t(label)}
            to={to.replace('{projectName}', projectName)}
            Icon={Icon}
            selected={pathname.endsWith(
              to.replace('{projectName}', projectName),
            )}
          />
        ))}
        <div css={{ flexGrow: 1 }} />
        <DrawerButton
          key="/account"
          label={fullName || t('Account')}
          to="/account"
          Icon={IconUser}
          avatarUrl={user.avatarUrl}
        />
        <DrawerButton
          key="/logout"
          label={t('Logout')}
          to="/logout"
          Icon={IconPowerOff}
        />
      </Drawer>
      <div
        css={{
          gridArea: 'children',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: 'var(--color-background)',
          overflowX: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Layout
