/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState, useEffect } from 'react'
import { jsx } from '@emotion/react'
import { useLocation } from 'react-router-dom'
import getPhrases from '@multilocale/client/getPhrases.js'
import fetchLandingPage from '../fetchLandingPage.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/landing-page']

function useSearchParams() {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const LandingPage = () => {
  let searchParams = useSearchParams()
  let url = searchParams.get('url')
  useLoggedInSession()
  const [html, setHtml] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function getLandingPageWithPhrases() {
      try {
        setLoading(true)

        let domain = url
        domain = domain.replace('https://', '')
        let splits = domain.split('/')
        domain = 'https://' + splits[0]

        let project = 'www'

        if (url.startsWith('https://www.waiterio.com/blog')) {
          project = 'blog'
        } else if (url.startsWith('https://www.waiterio.com/docs')) {
          project = 'docs'
        } else if (url.startsWith('https://www.waiterio.com/takeaway')) {
          project = 'takeaway'
        } else if (url.startsWith('https://www.waiterio.com')) {
          project = 'www'
        } else if (url.startsWith('https://www.multilocale.com/blog')) {
          project = 'polyblog-whitelabel-multilocale'
        } else if (url.startsWith('https://www.multilocale.com')) {
          project = 'multilocale-www'
        } else if (url.startsWith('https://www.polyblog.io/blog')) {
          project = 'polyblog-whitelabel-polyblog'
        } else if (url.startsWith('https://www.polyblog.io')) {
          project = 'polyblog'
        }

        let language = 'en'
        url.split('/').forEach(split => {
          if (split.length === 2) {
            language = split
          }
        })

        let html = await fetchLandingPage(url)

        html = html.replace(/<title.*<\/title>/, '')
        html = html.replace(/<meta.*<\/meta>/, '')
        html = html.replace(/<meta.*>/, '')

        let phrases = await getPhrases({ language, project })

        phrases = phrases.sort((a, b) => (a.value > b.value ? 1 : -1))

        phrases.forEach(({ key, value }) => {
          value = value.replaceAll("'", '&#x27;')
          html = html.replaceAll(
            value,
            `<span style="text-decoration-line:underline;text-decoration-style:wavy;cursor:pointer;" onclick="window.open('https://app.multilocale.com/projects/${project}/phrases/${key}')">${value}</span>`,
          )
        })

        html = html.replaceAll('src="/', `src="${domain}/`)

        html = html.replaceAll('href="/', `href="/landing-page?url=${domain}/`)

        html = html.replaceAll(
          `href="${domain}`,
          `href="/landing-page?url=${domain}`,
        )

        html = html.replaceAll(
          `href="${domain.replace('www.', '')}`,
          `href="/landing-page?url=${domain}`,
        )

        setHtml(html)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setError(error?.message)
      }
    }

    getLandingPageWithPhrases()
  }, [url])

  return (
    <div css={{ width: '100%', height: '100%' }}>
      {loading && <div className="loader" />}
      {error && <div>{error}</div>}

      {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
  )
}

export default LandingPage
