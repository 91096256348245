/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/react'
import Modal from '@stiloso/components/Modal.js'
import ModalHeader from '@stiloso/components/ModalHeader.js'
import clickable from '@stiloso/styles/clickable.js'

const DeleteHumanPhraseDialog = props => (
  <Modal close={props.close}>
    <ModalHeader>Confirm Delete</ModalHeader>

    <div
      css={{
        minHeight: 48,
        padding: 16,
        textAlign: 'center',
        border: 0,
        borderBottom: 1,
        borderColor: '#e4e4e4',
        borderStyle: 'solid',
        maxWidth: 250,
      }}
    >
      {props.message}
    </div>

    <div css={{ display: 'flex' }}>
      <div
        css={[
          clickable,
          {
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRight: '1px solid #e4e4e4',
            lineHeight: '48px',
          },
        ]}
        onClick={props.close}
      >
        No
      </div>

      <div
        css={[
          clickable,
          {
            cursor: 'pointer',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            lineHeight: '48px',
          },
        ]}
        onClick={() => props.delete(props.phrase)}
      >
        Yes
      </div>
    </div>
  </Modal>
)

export default DeleteHumanPhraseDialog
