/* Copyright 2013 - 2024 Waiterio LLC */
/** @jsx jsx */
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { jsx } from '@emotion/react'
import addPhrases from '@multilocale/client/addPhrases.js'
import useProject from '@multilocale/react/useProject.js'
import uuid from '@monorepo/shared/uuid.js'
import card from '@stiloso/styles/card.js'
import label from '@stiloso/styles/label.js'
import input from '@stiloso/styles/input.js'
import Button from '@stiloso/components/Button.js'
import section from '@stiloso/styles/section.js'
import sectionTitle from '@stiloso/styles/sectionTitle.js'
import { useTranslation } from '@multilocale/react/index.js'
import translateToAllLanguages from '@multilocale/client/translateToAllLanguages.js'
import Layout from '../components/Layout.js'
import useLoggedInSession from '../session/useLoggedInSession.js'

export const paths = ['/projects/:projectName/phrases/new']

const NewPhrasePage = () => {
  const { projectName } = useParams()
  const { t } = useTranslation()
  useLoggedInSession()
  const { data: project } = useProject(projectName)
  let [error, setError] = useState()
  let [progress, setProgress] = useState()
  let [key, setKey] = useState('')
  let [value, setValue] = useState('')

  const onChangeKey = event => {
    setKey(event.target.value)
  }

  const onChangeValue = event => {
    setValue(event.target.value)
  }

  const onSubmit = async event => {
    try {
      event.preventDefault()
      setError()

      const creationTime = new Date().getTime()
      const lastEditTime = creationTime

      if (!key) {
        key = value
      }

      let languages = project.locales

      languages = languages.filter(_ => _)
      languages = [...new Set(languages)]

      setProgress(
        t('Translating to all {languagesCount} languages...').replace(
          '{languagesCount}',
          languages.length,
        ),
      )

      let language2values = await translateToAllLanguages(value, languages)

      const phrases = Object.keys(language2values).map(language => ({
        _id: uuid(),
        creationTime,
        lastEditTime,
        key,
        value: language2values[language],
        language,
        googleTranslate: language !== project.defaultLocale,
        projects: [projectName],
      }))

      setProgress(t('Saving to the database') + '...')

      await addPhrases(phrases)

      setProgress(t('Completed!'))
    } catch (error) {
      setError(error && error.message)
    }
  }

  return (
    <Layout>
      <div css={section}>
        <div css={sectionTitle}>{t('New phrase')}</div>
        <form css={card} onSubmit={onSubmit}>
          <label htmlFor="valueInput" css={label}>
            {t('Value')}
          </label>
          <input
            id="valueInput"
            css={input}
            onChange={onChangeValue}
            value={value || ''}
            autoFocus
            required
          />

          <label htmlFor="keyInput" css={label}>
            {t('Key')}
          </label>
          <input
            id="keyInput"
            css={input}
            onChange={onChangeKey}
            value={key || ''}
            placeholder={value || ''}
          />

          {!error && progress && (
            <div
              css={{
                fontWeight: 'bold',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                margin: 16,
              }}
            >
              {progress}
            </div>
          )}

          {error && (
            <div
              css={{
                fontWeight: 'bold',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                margin: 16,
                color: 'var(--color-red)',
              }}
            >
              {error}
            </div>
          )}
          <Button type="submit" css={{ marginTop: 16 }} fullWidth>
            {t('Add phrase')}
          </Button>
        </form>
      </div>
    </Layout>
  )
}

export default NewPhrasePage
